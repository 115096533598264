import React, { FC } from "react";
import { Container, Row, Col } from "react-bootstrap";

import SimpleImage from "../../Shared/Media/SimpleImage";
import ProgramRequirementsImg from "../../../assets/images/program_requirements.webp";
import Heading from "../../Shared/Common/Heading";
import { FormattedMessage } from "react-intl";
import { Anchor } from "../../Shared/Common/Anchor";

const items = [
  {
    id: 1,
    src: ProgramRequirementsImg,
    isIntl: false,
    heading: "page.careers.area.ardp.program_requirements.header",
    title: "page.careers.area.ardp.program_requirements.title",
    description: "page.careers.area.ardp.program_requirements.description",
  },
];

const ProgramRequirements: FC = () => {
  return (
    <div className="careers-area-ardp-program-requirements">
      {items.map((item) => {
        return (
          <Container key={item.id}>
            <Heading type="section-heading">
              <FormattedMessage id={item.heading} />
            </Heading>
            <Row>
              <Col
                md={12}
                lg={6}
                className="program-requirements-image d-flex justify-content-center align-items-center"
              >
                <SimpleImage src={item.src} />
              </Col>
              <Col
                md={12}
                lg={6}
                className="d-flex justify-content-center align-items-center program-requirements-content"
              >
                <Row className="d-flex flex-column column-gap">
                  <Row>
                    <Heading type="section-sub-heading">
                      <FormattedMessage id={item.title} />
                    </Heading>
                  </Row>
                  <Row>
                    <Col md="auto">
                      <ul className="program-requirements-list">
                        <li>
                          <FormattedMessage id="page.careers.area.ardp.program_requirements.list_1" />
                        </li>
                        <li>
                          <FormattedMessage id="page.careers.area.ardp.program_requirements.list_2" />
                        </li>
                        <li>
                          <FormattedMessage id="page.careers.area.ardp.program_requirements.list_3" />
                        </li>
                        <li>
                          <FormattedMessage id="page.careers.area.ardp.program_requirements.list_4" />
                        </li>
                        <li>
                          <FormattedMessage id="page.careers.area.ardp.program_requirements.list_5" />
                        </li>
                      </ul>
                    </Col>
                  </Row>
                  <Row>
                    <p>
                      <FormattedMessage
                        id={item.description}
                        values={{
                          br: <br />,
                          website_link: (
                            <Anchor
                              href="https://www.nissan-global.com/EN/COMPANY/LIFE_AT_NISSAN/LEADING_A_GENDER-DIVERSE_TEAM/"
                              href_jp="https://www.nissan-global.com/JP/COMPANY/LIFE_AT_NISSAN/LEADING_A_GENDER-DIVERSE_TEAM/"
                              external={true}
                            >
                              <FormattedMessage id="page.common.learn_more" />
                            </Anchor>
                          ),
                        }}
                      />
                    </p>
                  </Row>
                </Row>
              </Col>
            </Row>
          </Container>
        );
      })}
    </div>
  );
};
export default ProgramRequirements;
