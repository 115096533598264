import { FC } from "react";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

import SimpleImage from "../../Shared/Media/SimpleImage";
interface Props {
  src: string;
  title: string;
  description: string;
}
const SocialNetworksCard: FC<Props> = ({ title, description, src }) => {
  return (
    <Card className="social-networks-card">
      <span className="bg-img">
        <SimpleImage src={src} />
      </span>
      <div className="card-details">
        <p>
          <FormattedMessage id={description} />
        </p>
      </div>

      <div className="card-footer">
        <div className="card-footer-details">
          <span>
            <FormattedMessage id={title} />
          </span>
        </div>
      </div>

      <div className="card-title">
        <h2>
          <FormattedMessage id={title} />
        </h2>
      </div>
    </Card>
  );
};
export default SocialNetworksCard;
