import { FC } from "react";

import SocialNetworkCardSection from "./_card_sections";

const SocialNetwork: FC = () => {
  return (
    <div className="careers-area-ardp-social-network">
      <SocialNetworkCardSection />
    </div>
  );
};
export default SocialNetwork;
