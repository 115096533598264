import { FC, useState, useEffect } from "react";
import { Row, Dropdown, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

import SocialNetworksCard from "./_card";
import { SOCIAL_NETWORK_ITEMS } from "./_items";

interface Program {
  id: number;
  src: string;
  title: string;
  description: string;
}
const SocialNetworksMobile: FC = () => {
  const [programId, setProgramId] = useState<number>(1);
  const [programs, setPrograms] = useState<Program>();

  const onSelect = (eventKey: any, event: Object) => {
    setProgramId(Number(eventKey));
  };

  useEffect(() => {
    if (programId)
      setPrograms(SOCIAL_NETWORK_ITEMS.find((item) => item.id === programId));
  }, [programId]);

  return (
    <Row>
      <Dropdown onSelect={onSelect}>
        <Dropdown.Toggle id="dropdown-basic">
          {programs && <FormattedMessage id={programs.title} />}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {SOCIAL_NETWORK_ITEMS.map((item, index) => {
            return (
              <Dropdown.Item key={item.id} eventKey={item.id}>
                <FormattedMessage id={item.title} />
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
      {programs && (
        <Col lg={4} sm={12}>
          <SocialNetworksCard {...programs} />
        </Col>
      )}
    </Row>
  );
};
export default SocialNetworksMobile;
