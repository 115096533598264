import Testimonial1 from "../../../assets/images/careerarea-ardp/testimonial_1.webp";
import Testimonial2 from "../../../assets/images/careerarea-ardp/testimonial_2.webp";
import Testimonial3 from "../../../assets/images/careerarea-ardp/testimonial_3.webp";

export const TESTIMONIAL_ITEMS = [
  {
    id: 1,
    profile: Testimonial1,
    empName: "Camila Ciordia Tailhade",
    empCountry: "page.careers.area.ardp.testimonials_1_designation",
    detail: "page.careers.area.ardp.testimonials_1_description",
    japanese: false,
  },
  {
    id: 2,
    profile: Testimonial2,
    empName: "Huang-Kai Chen",
    empCountry: "page.careers.area.ardp.testimonials_2_designation",
    detail: "page.careers.area.ardp.testimonials_2_description",
    japanese: false,
  },
  {
    id: 3,
    profile: Testimonial3,
    empName: "Antonio Mont’Alverne",
    empCountry: "page.careers.area.ardp.testimonials_3_designation",
    detail: "page.careers.area.ardp.testimonials_3_description",
    japanese: false,
  },
];
