import { FC } from "react";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import SimpleImage from "../../Shared/Media/SimpleImage";

import GreatPlaceToWorkImage from "../../../assets/images/nissan-gptw-logo.svg";
import GreatPlaceToWorkUsa from "../../../assets/images/gptw_usa.webp";
import GreatPlaceToWorkPeru from "../../../assets/images/gptw_peru.webp";
import GreatPlaceToWorkArgentina from "../../../assets/images/gptw_arg.webp";
import GreatPlaceToWorkIndia from "../../../assets/images/gptw_india.webp";
import GreatPlaceToWorkAus from "../../../assets/images/gptw_aus.webp";
import GreatPlaceToWorkChile from "../../../assets/images/gptw_chile.webp";
import GreatPlaceToWorkBrazil from "../../../assets/images/gptw_brasil.webp";
import GreatPlaceToWorkNz from "../../../assets/images/gptw_nz.webp";

const badges = [
  {
    id: 1,
    src: GreatPlaceToWorkArgentina,
  },
  {
    id: 2,
    src: GreatPlaceToWorkBrazil,
  },
  {
    id: 3,
    src: GreatPlaceToWorkPeru,
  },
  {
    id: 4,
    src: GreatPlaceToWorkUsa,
  },
  {
    id: 5,
    src: GreatPlaceToWorkChile,
  },
  {
    id: 6,
    src: GreatPlaceToWorkAus,
  },
  {
    id: 7,
    src: GreatPlaceToWorkNz,
  },
  {
    id: 8,
    src: GreatPlaceToWorkIndia,
  },
];

const GreatPlaceToWork: FC = () => {
  return (
    <div className="great-place-to-work">
      <div className="container p-0">
        <div className="row no-gutters">
          <div className="col-12 col-md-6">
            <div className="nissan-logo-sec">
              <div className="nissan-logo-img">
                <SimpleImage src={GreatPlaceToWorkImage} />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 certificate-slide">
            <Splide
              options={{
                arrows: false,
                pagination: false,
                width: "100%",
                perPage: 1,
                perMove: 1,
                rewind: true,
                autoplay: true,
                pauseOnHover: false,
                resetProgress: false,
                interval: 6000,
              }}
              aria-labelledby={"GreatPlaceToWorkImage-sections"}
            >
              {badges.map((badge) => {
                return (
                  <SplideSlide key={badge.id}>
                    <SimpleImage src={badge.src} />
                  </SplideSlide>
                );
              })}
            </Splide>
          </div>
        </div>
      </div>
    </div>
  );
};
export default GreatPlaceToWork;
