export const FAQDATA = [
  {
    question: "page.careers.area.ardp.faq.question_1",
    answer: "page.careers.area.ardp.faq.answer_1",
  },
  {
    question: "page.careers.area.ardp.faq.question_2",
    answer: "page.careers.area.ardp.faq.answer_2",
  },
  {
    question: "page.careers.area.ardp.faq.question_3",
    answer: "page.careers.area.ardp.faq.answer_3",
  },
  {
    question: "page.careers.area.ardp.faq.question_4",
    answer: "page.careers.area.ardp.faq.answer_4",
  },
  {
    question: "page.careers.area.ardp.faq.question_5",
    answer: "page.careers.area.ardp.faq.answer_5",
  },
  {
    question: "page.careers.area.ardp.faq.question_6",
    answer: "page.careers.area.ardp.faq.answer_6",
  },
];
