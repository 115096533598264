import PeerConnections from "../../../assets/images/careerarea-ardp/peer-connections.webp";
import IndustryExperts from "../../../assets/images/careerarea-ardp/industry-experts.webp";
import AlumniCommunity from "../../../assets/images/careerarea-ardp/alumni-community.webp";

export const SOCIAL_NETWORK_ITEMS = [
  {
    id: 1,
    src: PeerConnections,
    title: "page.careers.area.ardp.social_networks.title_1",
    description: "page.careers.area.ardp.social_networks.description_1",
  },
  {
    id: 2,
    src: IndustryExperts,
    title: "page.careers.area.ardp.social_networks.title_2",
    description: "page.careers.area.ardp.social_networks.description_2",
  },
  {
    id: 3,
    src: AlumniCommunity,
    title: "page.careers.area.ardp.social_networks.title_3",
    description: "page.careers.area.ardp.social_networks.description_3",
  },
];
