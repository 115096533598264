import { FC } from "react";
import { FormattedMessage } from "react-intl";

import { Anchor } from "../../Shared/Common/Anchor";
import SimpleImage from "../../Shared/Media/SimpleImage";
import ApplyIcon from "../../../assets/images/nml/apply_icon.svg";

const ArdpApplyArea: FC = () => {
  return (
    <div className="careers-area-ardp-apply">
      <h2>
        <FormattedMessage id="page.careers.area.ardp.apply.title" />
      </h2>
      <p>
        <FormattedMessage id="page.careers.area.ardp.apply.description" />
      </p>
      {/* <Anchor href="#"> */}
      <button className="btn btn-primary" disabled>
        <FormattedMessage id="page.careers.area.ardp.apply.button" />
        <SimpleImage src={ApplyIcon} className="ps-2" />
      </button>
      {/* </Anchor> */}
    </div>
  );
};
export default ArdpApplyArea;
