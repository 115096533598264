import { FC } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import SimpleImage from "../../Shared/Media/SimpleImage";

import CEOSrc from "../../../assets/images/about-driving-innovation.webp";
import { AnchorButton } from "../../Shared/Common/Anchor/Button";
const DrivingInnovation: FC = () => {
  const intl = useIntl();
  const { locale } = intl;

  return (
    <>
      <div className="driving-inovation-area">
        <Container>
          <Row>
            <Col lg={6} md={12} sm={7} xs={12}>
              <Row>
                <Col lg={10} className="ml-auto">
                  <div className="inovation-cont">
                    {/* <h2 style={{ whiteSpace: "pre-wrap" }}>
                      <FormattedMessage id="page.about.ceo_message_title" />
                    </h2> */}
                    <p>
                      <FormattedMessage
                        id="page.about.ceo_message_description"
                        values={{ br: <br /> }}
                      />
                    </p>
                    <div className="py-3 president-detail">
                      {locale === "en" ? (
                        <h3>
                          <FormattedMessage id="page.about.ceo.name" />
                        </h3>
                      ) : (
                        <p className="my-0">
                          <FormattedMessage id="page.about.ceo.name" />
                        </p>
                      )}
                      <p className="my-0">
                        <FormattedMessage id="page.about.ceo.designation" />
                      </p>

                      {locale === "ja" ? (
                        <h3>
                          <FormattedMessage id="page.about.ceo.company" />
                        </h3>
                      ) : (
                        <p>
                          <FormattedMessage id="page.about.ceo.company" />
                        </p>
                      )}
                    </div>
                    <div className="w-100 only-desktop">
                      <AnchorButton
                        href={
                          "https://www.nissan-global.com/EN/COMPANY/MESSAGE/"
                        }
                        href_jp={
                          "https://www.nissan-global.com/JP/COMPANY/MESSAGE/"
                        }
                        variant={"primary"}
                        external={true}
                      >
                        <FormattedMessage id="page.common.see_more" />
                      </AnchorButton>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={5} xs={12}>
              <div className="inovation-img">
                <SimpleImage src={CEOSrc} className="full-width" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Row className="only-mobile mx-auto ">
        <Col className="py-3 d-flex justify-content-center">
          <AnchorButton
            href={"https://www.nissan-global.com/EN/COMPANY/MESSAGE/"}
            href_jp={"https://www.nissan-global.com/JP/COMPANY/MESSAGE/"}
            variant={"secondary"}
            external={true}
            ewhite={true}
          >
            <FormattedMessage id="page.common.see_more" />
          </AnchorButton>
        </Col>
      </Row>
    </>
  );
};
export default DrivingInnovation;
