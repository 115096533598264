import ArdpApplyArea from "../CareersAreaArdp/ArdpApply";
import ProfileCard from "./ProfileCard";
import InterviewDetails from "./InterviewDetails";

const CareersAreaArdpInterview = () => {
  return (
    <div className="careers-ardp-main careers-ardp-interview-main">
      <ProfileCard />
      <InterviewDetails />
      <ArdpApplyArea />
    </div>
  );
};
export default CareersAreaArdpInterview;
