import { FC } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

import { INTERVIEW_TIPS } from "./_items";

import CardHeader from "../../Shared/Common/Card/CardHeader";
const headerItems = {
  title: "page.careers.area.ardp.interview_tips.header",
};

const InterviewTips: FC = () => {
  return (
    <div className="careers-area-ardp-interview-tips">
      <Container>
        <CardHeader {...headerItems}></CardHeader>
        <Row>
          {INTERVIEW_TIPS.map((item, index) => {
            return (
              <Col lg={4} sm={12} md={6} key={index}>
                <Card>
                  <Card.Header>
                    <Card.Title>
                      <h6>
                        <FormattedMessage id={item.title} />
                      </h6>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <p>
                      <FormattedMessage id={item.description} />
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default InterviewTips;
