import { FC } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import SimpleImage from "../../Shared/Media/SimpleImage";
import { AnchorButton } from "../../Shared/Common/Anchor/Button";
import { CAREER_ARDP_INTERVIEW_PAGE } from "../../../interfaces/Static/routing_paths";

import InterviewJourneySrc from "../../../assets/images/careerarea-ardp/interview-journey.webp";

const InterviewJourney: FC = () => {
  return (
    <>
      <div className="careers-area-ardp-interview-journey">
        <Container>
          <Row>
            <Col lg={6} md={12} sm={7} xs={12}>
              <Row>
                <Col lg={10} className="ml-auto">
                  <div className="interview-journey-content">
                    <h2 style={{ whiteSpace: "pre-wrap" }}>
                      <FormattedMessage id="page.careers.area.ardp.interview_journey.title" />
                    </h2>
                    <p>
                      <FormattedMessage id="page.careers.area.ardp.interview_journey.description" />
                    </p>
                    <div className="py-3 manager-detail">
                      <h3>
                        <FormattedMessage id="page.careers.area.ardp.interview_journey.name" />
                      </h3>
                      <p className="my-0">
                        <FormattedMessage id="page.careers.area.ardp.interview_journey.designation" />
                      </p>
                      <p>
                        <FormattedMessage id="page.careers.area.ardp.interview_journey.company" />
                      </p>
                    </div>
                    <div className="w-100 only-desktop">
                      <AnchorButton
                        href={CAREER_ARDP_INTERVIEW_PAGE}
                        variant={"primary"}
                      >
                        <FormattedMessage id="page.common.see_more" />
                      </AnchorButton>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={5} xs={12}>
              <div className="interview-journey-img">
                <SimpleImage src={InterviewJourneySrc} className="full-width" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Row className="only-mobile mx-auto ">
        <Col className="py-3 d-flex justify-content-center">
          <AnchorButton
            href={CAREER_ARDP_INTERVIEW_PAGE}
            variant={"secondary"}
            ewhite={true}
          >
            <FormattedMessage id="page.common.see_more" />
          </AnchorButton>
        </Col>
      </Row>
    </>
  );
};
export default InterviewJourney;
