import { FC } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import SimpleImage from "../../Shared/Media/SimpleImage";
import { AnchorButton } from "../../Shared/Common/Anchor/Button";
import { CAREER_ARDP_INTERVIEW_PAGE } from "../../../interfaces/Static/routing_paths";

import ProfileCardSrc from "../../../assets/images/careerarea-ardp/interview/profile-card.webp";

const ProfileCard: FC = () => {
  return (
    <div className="careers-area-ardp-interview-profile-card">
      <Container className="d-flex align-items-center">
        <Row>
          <Col lg={6} md={12} sm={12} xs={12}>
            <Row>
              <Col lg={12} className="ml-auto">
                <div className="profile-card-content">
                  <h1 style={{ whiteSpace: "pre-wrap" }}>
                    <FormattedMessage id="page.careers.area.ardp.interview.profile_card.title" />
                  </h1>
                  <p>
                    <FormattedMessage id="page.careers.area.ardp.interview.profile_card.description_1" />
                  </p>
                  <p>
                    <FormattedMessage id="page.careers.area.ardp.interview.profile_card.description_2" />
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={6} md={12} sm={12} xs={12}>
            <div className="profile-card-img">
              <SimpleImage src={ProfileCardSrc} className="full-width" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default ProfileCard;
