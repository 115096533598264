export const HOME_PAGE = "/";
export const ABOUT_PAGE = "/about";
export const CAREER_PAGE = "/careerarea";
export const CAREER_DETAILS_PAGE = "/careerarea-details";
export const CAREER_ARDP_PAGE = "/accelerated-rotational-development-program";
export const CAREER_ARDP_INTERVIEW_PAGE =
  "/accelerated-rotational-development-program-interview";
export const MEET_OUR_PEOPLE_PAGE = "/meet";
export const SITEMAP_PAGE = "/sitemap";
export const AMERICAS_PAGE = "/americas";
export const EUROPE_PAGE = "/europe";
export const ASEAN_PAGE = "/asean";
export const AFRICA_PAGE = "/africa";
export const NML_PAGE = "/japan";
export const MIDDLE_EAST_PAGE = "/middle-east";
export const AUSTRALIA_NEWZEALAND_PAGE = "/australia-newzealand";
export const BASENAME_PAGE = ""; // empty for prod
export const RECRUITMENT_SCAM_PAGE = "recuritment-hiring-scam";

export const WORKDAY_EN_PATH =
  "https://alliance.wd3.myworkdayjobs.com/en-US/nissanjobs?";
export const WORKDAY_JP_PATH =
  "https://alliance.wd3.myworkdayjobs.com/ja-JP/nissanjobs?";
