export const INTERVIEW_TIPS = [
  {
    id: 1,
    title: "page.careers.area.ardp.interview_tips.title_1",
    description: "page.careers.area.ardp.interview_tips.description_1",
  },
  {
    id: 2,
    title: "page.careers.area.ardp.interview_tips.title_2",
    description: "page.careers.area.ardp.interview_tips.description_2",
  },
  {
    id: 3,
    title: "page.careers.area.ardp.interview_tips.title_3",
    description: "page.careers.area.ardp.interview_tips.description_3",
  },
  {
    id: 4,
    title: "page.careers.area.ardp.interview_tips.title_4",
    description: "page.careers.area.ardp.interview_tips.description_4",
  },
  {
    id: 5,
    title: "page.careers.area.ardp.interview_tips.title_5",
    description: "page.careers.area.ardp.interview_tips.description_5",
  },
];
