import CareersAreaArdp from "../Components/CareersAreaArdp";

import { Helmet } from "react-helmet-async";

function CareersAreaArdpContainer() {
  return (
    <>
      <Helmet>
        <title>
          NISSAN MOTOR CORPORATION - GLOBAL CAREER WEBSITE - CAREER AREAS -
          OVERVIEW
        </title>
      </Helmet>
      <main className="career-area-container career-area-details-container">
        <CareersAreaArdp />
      </main>
    </>
  );
}

export default CareersAreaArdpContainer;
