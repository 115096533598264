import { FC } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import SimpleImage from "../../Shared/Media/SimpleImage";

import InterviewDetails2 from "../../../assets/images/careerarea-ardp/interview/details-2.webp";
import InterviewDetails3 from "../../../assets/images/careerarea-ardp/interview/details-3.webp";
import InterviewDetails4 from "../../../assets/images/careerarea-ardp/interview/details-4.webp";

const InterviewDetails: FC = () => {
  return (
    <div className="careers-area-ardp-interview-details">
      <Container>
        <Row className="gap-4 interview-details">
          <h4 style={{ whiteSpace: "pre-wrap" }}>
            <FormattedMessage id="page.careers.area.ardp.interview.details_1.question" />
          </h4>
          <p>
            <FormattedMessage
              id="page.careers.area.ardp.interview.details_1.answer"
              values={{
                br: <br />,
              }}
            />
          </p>
        </Row>
        <Row className="flex-lg-nowrap interview-details">
          <Col
            xxl={7}
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className="custom-space-pr"
          >
            <div className="interview-details-img">
              <SimpleImage src={InterviewDetails2} className="full-width" />
            </div>
          </Col>
          <Col
            xxl={5}
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className="custom-space-pt"
          >
            <Row className="gap-4">
              <h4 style={{ whiteSpace: "pre-wrap" }}>
                <FormattedMessage id="page.careers.area.ardp.interview.details_2.question" />
              </h4>
              <p>
                <FormattedMessage
                  id="page.careers.area.ardp.interview.details_2.answer"
                  values={{
                    br: <br />,
                  }}
                />
              </p>
            </Row>
          </Col>
        </Row>
        <Row className="flex-lg-nowrap interview-details">
          <Col
            xxl={5}
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className="custom-space-pr"
          >
            <Row className="gap-4">
              <h4 style={{ whiteSpace: "pre-wrap" }}>
                <FormattedMessage id="page.careers.area.ardp.interview.details_3.question" />
              </h4>
              <p>
                <FormattedMessage
                  id="page.careers.area.ardp.interview.details_3.answer"
                  values={{
                    br: <br />,
                  }}
                />
              </p>
            </Row>
          </Col>
          <Col xxl={7} lg={6} md={12} sm={12} xs={12}>
            <div className="interview-details-img">
              <SimpleImage src={InterviewDetails3} className="full-width" />
            </div>
          </Col>
        </Row>
        <Row className="interview-details">
          <div className="interview-details-img">
            <SimpleImage src={InterviewDetails4} className="full-width" />
          </div>
        </Row>
        <Row className="gap-4 interview-details">
          <h4 style={{ whiteSpace: "pre-wrap" }}>
            <FormattedMessage id="page.careers.area.ardp.interview.details_5.question" />
          </h4>
          <p>
            <FormattedMessage
              id="page.careers.area.ardp.interview.details_5.answer"
              values={{
                br: <br />,
                b: (chunks) => <strong>{chunks}</strong>,
              }}
            />
          </p>
        </Row>
      </Container>
    </div>
  );
};
export default InterviewDetails;
