/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import LOGO from "../../../assets/images/logo.svg";
import LOGO_90 from "../../../assets/images/logo_90.svg";
import LOGO_TEXT from "../../../assets/images/logo_text.svg";
import SimpleImage from "../../Shared/Media/SimpleImage";
import { Anchor } from "../../Shared/Common/Anchor";

import { HOME_PAGE } from "../../../interfaces/Static/routing_paths";
import { useLocation } from "react-router-dom";

interface Props {
  language: string;
}

const celebration_link_en =
  "https://www.nissan-global.com/EN/COMPANY/90ANNIVERSARY/";

const celebration_link_jp =
  "https://www.nissan-global.com/JP/COMPANY/90ANNIVERSARY/";

const image_alt = "NISSAN MOTOR CORPORATION - GLOBAL CAREER WEBSITE";

const HeaderLogo: FC<Props> = ({ language }) => {
  const location = useLocation();
  return (
    <>
      <Anchor href={HOME_PAGE}>
        <SimpleImage className="logo-img" src={LOGO} alt={image_alt} />
        <SimpleImage
          className="logo-text-img"
          src={LOGO_TEXT}
          alt={image_alt}
        />
      </Anchor>
    </>
  );
};
export default HeaderLogo;
