import CareerAreaArdpBanner from "../Shared/Banner/CareersAreaArdp";

import AccelerateCareer from "./AccelerateCareer";
import SocialNetwork from "./SocialNetwork";
import Testimonials from "./Testimonials";
import LeverageMentorship from "./LeverageMentorship";
import ArdpApplyArea from "./ArdpApply";
import ProgramRequirements from "./ProgramRequirements";
import InterviewJourney from "./InterviewJourney";
import InterviewTips from "./InterviewTips";
import Faq from "./Faq";

const CareersAreaArdp = () => {
  return (
    <div className="careers-ardp-main">
      <CareerAreaArdpBanner banners={[]} />
      <AccelerateCareer />
      <SocialNetwork />
      <LeverageMentorship />
      <Testimonials />
      <ProgramRequirements />
      <InterviewJourney />
      <InterviewTips />
      <Faq />
      <ArdpApplyArea />
    </div>
  );
};
export default CareersAreaArdp;
