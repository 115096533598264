import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { isMobile, isTablet } from "react-device-detect";
import { Row, Col, Container } from "react-bootstrap";

import Heading from "../../Shared/Common/Heading";
import { SOCIAL_NETWORK_ITEMS } from "./_items";
import SocialNetworksCard from "./_card";
import SocialNetworksMobile from "./_mobile";

const SocialNetworksCardSection: FC = () => {
  return (
    <Container>
      <Row>
        <Col lg={12} md={12} className="pb-3">
          <Heading type="section-heading">
            <FormattedMessage id="page.careers.area.ardp.social_network.title" />
          </Heading>
        </Col>
      </Row>
      {isMobile && !isTablet ? (
        <SocialNetworksMobile />
      ) : (
        <Row>
          {SOCIAL_NETWORK_ITEMS.map((item) => {
            return (
              <Col lg={4} md={4} xs={12} key={item.id}>
                <SocialNetworksCard {...item} />
              </Col>
            );
          })}
        </Row>
      )}
    </Container>
  );
};
export default SocialNetworksCardSection;
