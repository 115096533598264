import { FC } from "react";
import { Container, Row, Col } from "react-bootstrap";

import { TESTIMONIAL_ITEMS } from "./_items";
import TestimonialsCard from "../../Shared/Common/Testimonials/_card";
import CardHeader from "../../Shared/Common/Card/CardHeader";
const headerItems = {
  title: "page.careers.area.ardp.testimonial.header",
};

const Testimonials: FC = () => {
  return (
    <div className="careers-area-ardp-testimonial">
      <Container>
        <CardHeader {...headerItems}></CardHeader>
        <Row>
          {TESTIMONIAL_ITEMS.map((item, index) => {
            return (
              <Col lg={4} sm={12} md={6} key={index}>
                <TestimonialsCard {...item} />
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default Testimonials;
