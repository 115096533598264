import { FC } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import Card from "./_card";

const AccelerateCareer: FC = () => {
  return (
    <div className="careers-area-ardp-accelerate-career">
      <Container>
        <Col>
          <Row className="d-flex gap-4">
            <h2>
              <FormattedMessage id="page.careers.area.ardp.accelerate-career.title" />
            </h2>
            <p>
              <FormattedMessage id="page.careers.area.ardp.accelerate-career.description" />
            </p>
            <Card />
          </Row>
        </Col>
      </Container>
    </div>
  );
};
export default AccelerateCareer;
