import { FC } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import SimpleImage from "../../Shared/Media/SimpleImage";
import LeverageMentorshipSrc from "../../../assets/images/careerarea-ardp/leverage-mentorship.webp";

const LeverageMentorship: FC = () => {
  return (
    <div className="careers-area-ardp-leverage-mentorship">
      <Container fluid>
        <Row>
          <Col lg={6} md={12} sm={12} xs={12}>
            <div className="leverage-mentorship-img">
              <SimpleImage src={LeverageMentorshipSrc} className="full-width" />
            </div>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className="leverage-mentorship-content"
          >
            <Row>
              <h2>
                <FormattedMessage id="page.careers.area.ardp.leverage_mentorship.heading" />
              </h2>
            </Row>
            <Row>
              <Col className="">
                <h4>
                  <FormattedMessage id="page.careers.area.ardp.leverage_mentorship.title_1" />
                </h4>
                <p>
                  <FormattedMessage id="page.careers.area.ardp.leverage_mentorship.description_1" />
                </p>
              </Col>
            </Row>
            <Row>
              <Col className="">
                <h4>
                  <FormattedMessage id="page.careers.area.ardp.leverage_mentorship.title_2" />
                </h4>
                <p>
                  <FormattedMessage id="page.careers.area.ardp.leverage_mentorship.description_2" />
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default LeverageMentorship;
