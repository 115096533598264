import React, { FC, useRef, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";

import Heading from "../../Shared/Common/Heading";
import SimpleImage from "../../Shared/Media/SimpleImage";
import Program1 from "../../../assets/images/Program1.webp";
import Leftarrow from "../../../assets/images/left-arrow.svg";
import Rightarrow from "../../../assets/images/right-arrow.svg";
import { FormattedMessage, useIntl } from "react-intl";
import { AnchorButton } from "../../Shared/Common/Anchor/Button";
import { CAREER_ARDP_PAGE } from "../../../interfaces/Static/routing_paths";
const itemsLength = 2;
const Program: FC = () => {
  const intl = useIntl();
  const { locale } = intl;
  const ref = useRef(null);
  const [index, setIndex] = useState(0);
  const handleSelect = (
    selectedIndex: React.SetStateAction<number>,
    e: any
  ) => {
    setIndex(selectedIndex);
  };
  const onPrev = () => {
    setIndex(index - 1);
  };
  const onNext = () => {
    setIndex(index + 1);
  };

  return (
    <div className="corporate-program-area">
      <Container>
        <Row className="py-4">
          <Heading type="section-heading">
            Accelerated Rotational Development Program
          </Heading>
          {/* <div className="slider-count">
            {index + 1} / {itemsLength}
          </div> */}
        </Row>
        {/* <Carousel
          ref={ref}
          onSlide={(e) => setIndex(e)}
          onSelect={handleSelect}
          controls={false}
          activeIndex={index}
          indicators={false}
        >
          <Carousel.Item key={0}> */}
        <Row>
          <Col sm={12} md={12} lg={6}>
            <SimpleImage src={Program1} />
          </Col>
          <Col sm={12} md={12} lg={6} className="slide-cont">
            {locale === "en" ? (
              <p>
                If you are an MBA graduate, versatile, adaptable, keen to learn
                and, most importantly, aspire to steer your career on a true
                business leader path, then our{" "}
                <strong>accelerated rotational development program</strong> is
                the natural next step for you.
              </p>
            ) : (
              <p>
                日産自動車では、MBAを取得し、多才で適応力があり、かつ学習意欲が高く、そして何よりも真のビジネスリーダーとしてのキャリアを歩むことを強く望む方を支援するために、
                <strong>accelerated rotational development program</strong>{" "}
                があります。
              </p>
            )}

            {locale === "en" ? (
              <p>
                We offer an exciting professional journey of accelerated
                onboarding with stretch assignments tailored for each
                participant’s experience and career path, cross-functional and
                cross-regional rotations to build the knowledge and skills
                needed to become a regional & global leader in the fast-changing
                automotive world, leadership development programs and continuous
                support throughout the entire journey. Interested in{" "}
                <strong>growing</strong> with us?
              </p>
            ) : (
              <p>
                このプログラムは、参加者一人ひとりの経験やキャリアパスに合わせたアサインメントを提供し、急速に変化する自動車業界において各地域のリーダーまたはグローバルリーダーになるために必要な知識とスキルを身につけることを目的としています。地域での横断的なジョブローテーション、リーダーシップ研修など、継続的なキャリアサポートを通じて、プロフェッショナルとしてエキサイティングなキャリアを歩む事ことができます。
              </p>
            )}
            {/* <p>
              {locale === "en"
                ? "Contact our team at:"
                : "詳細はこちらに英語でお問合せください"}
              <br />
              Rotation_Program@mail.nissan.co.jp
            </p> */}
            {/* <AnchorButton
                      href={item.link}
                      external={true}
                      variant={"primary"}
                    >
                      <FormattedMessage id={"page.common.learn_more"} />
                    </AnchorButton> */}
          </Col>
        </Row>
        <Row className="pt-4">
          <div className="d-flex justify-content-center">
            <AnchorButton href={CAREER_ARDP_PAGE} variant={"primary"}>
              <FormattedMessage id={"page.common.learn_more"} />
            </AnchorButton>
          </div>
        </Row>
        {/* </Carousel.Item>
        </Carousel> */}
      </Container>
      {/* <div className="program-area-nav">
        <Container>
          <Row>
            <Col className="d-flex justify-content-start">
              <Button
                variant="link"
                onClick={onPrev}
                disabled={index === 0 ? true : false}
              >
                <SimpleImage src={Leftarrow} />
                <span className="px-2">
                  <FormattedMessage id="page.common.previous" />
                </span>
              </Button>
            </Col>
            <Col className="d-flex justify-content-end">
              <Button
                variant="link"
                onClick={onNext}
                disabled={index === itemsLength - 1 ? true : false}
              >
                <span className="px-2">
                  <FormattedMessage id="page.common.next" />
                </span>
                <SimpleImage src={Rightarrow} />
              </Button>
            </Col>
          </Row>
        </Container>
      </div> */}
    </div>
  );
};
export default Program;
