import { FC, useState } from "react";
import { Row, Col, Container, Accordion } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import PlusIcon from "../../../assets/images/plus.svg";
import MinusIcon from "../../../assets/images/minus.svg";
import { FAQDATA } from "./_items";
import SimpleImage from "../../Shared/Media/SimpleImage";

const Faq: FC = () => {
  const [activeKey, setActiveKey] = useState(null);

  const handleToggle = (key: any) => {
    setActiveKey(activeKey === key ? null : key);
  };
  return (
    <div className="careers-area-ardp-faq">
      <Container>
        <Row>
          <h2 className="mb-5">
            <FormattedMessage id="page.careers.area.ardp.faq.title" />
          </h2>
        </Row>
        <Row>
          <Col lg={12}>
            <Accordion
              activeKey={activeKey}
              onSelect={handleToggle}
              className="faq-accordion"
            >
              {FAQDATA.map((item, index) => (
                <Accordion.Item eventKey={index.toString()} key={index}>
                  <Accordion.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 pe-3">
                      <span>
                        <FormattedMessage id={item.question} />
                      </span>
                      <div className="icon-container">
                        {activeKey === index.toString() ? (
                          <SimpleImage
                            src={MinusIcon}
                            className="accordion-icon active"
                          />
                        ) : (
                          <SimpleImage
                            src={PlusIcon}
                            className="accordion-icon"
                          />
                        )}
                      </div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <FormattedMessage id={item.answer} />
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Faq;
