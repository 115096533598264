import { FC } from "react";
import NewsCard from "../../Shared/Common/Card/NewsCard";

import GlobalExposure from "../../../assets/images/careerarea-ardp/global-exposure.webp";
import KeyAssignments from "../../../assets/images/careerarea-ardp/key-assignments.webp";
import PersonalizedCoaching from "../../../assets/images/careerarea-ardp/personalized-coaching.webp";

const news_card_props = {
  items: [
    {
      id: 1,
      name: "page.careers.area.ardp.accelerate_career.global_exposure",
      description:
        "page.careers.area.ardp.accelerate_career.global_exposure.description",
      src: GlobalExposure,
    },
    {
      id: 2,
      name: "page.careers.area.ardp.accelerate_career.key_assignments",
      description:
        "page.careers.area.ardp.accelerate_career.key_assignments.description",
      src: KeyAssignments,
    },
    {
      id: 3,
      name: "page.careers.area.ardp.accelerate_career.personalized_coaching",
      description:
        "page.careers.area.ardp.accelerate_career.personalized_coaching.description",
      src: PersonalizedCoaching,
    },
  ],
};
const Card: FC = () => {
  return (
    <div className="accelerate-career-card">
      <NewsCard {...news_card_props} />
    </div>
  );
};
export default Card;
