import { ASEAN_SUB_PAGES } from "../../Config/Static/ProgramDetail/Asean";
import { ASEAN_STORIES } from "../../Config/Static/Stories/asean-stories";
import DEI1 from "../../assets/images/asean/dei_1.webp";
import DEI2 from "../../assets/images/asean/dei_2.webp";
import DEI3 from "../../assets/images/asean/dei_3.webp";
import DEI4 from "../../assets/images/asean/dei_4.webp";
import DEI5 from "../../assets/images/asean/dei_5.webp";
import DEI6 from "../../assets/images/asean/dei_6.webp";

import TestimonialsMaicaAngelicaOrsendo from "../../assets/images/asean/testimonials_maica_angelica_orsendo.webp";
import TestimonialsMayTherse from "../../assets/images/asean/testimonials_may_therese.webp";
import TestimonialsEngJooLim from "../../assets/images/asean/testimonials_joo_lim.webp";

export const ASEANS_DRIVING_TOGETHER = {
  heading: "page.common.driving_together",
  title: "page.common.driving_together_title",
  description: "page.common.driving_together_description",
  href: "https://europe.nissanstories.com/en/releases/fostering-a-safe-space-for-everyone-nissan-amieo-africa-middle-east-india-europe-oceania-shares-its-first-diversity-equity-and-inclusion-dei-report/",
  href_jp:
    "https://europe.nissanstories.com/en/releases/fostering-a-safe-space-for-everyone-nissan-amieo-africa-middle-east-india-europe-oceania-shares-its-first-diversity-equity-and-inclusion-dei-report",
  disableLink: true,
  src: [DEI1, DEI2, DEI3, DEI4, DEI5, DEI6],
  detailed_description: "page.asean.driving_together_descirption_bottom",
};

export const ASEAN_JOURNEY = {
  heading: "page.discover.your_journey",
  title: "page.discover.your_journey.title",
  description: "page.discover.your_journey.description",
  items: ASEAN_SUB_PAGES,
  sub_description: "page.discover.your_journey_description_bottom",
};

export const ASEAN_STORY = {
  title: "page.stories.asean_title",
  items: ASEAN_STORIES,
  href: "https://asean.nissannews.com/en/",
  href_jp: "https://asean.nissannews.com/en/",
};

export const ASEAN_TESTIMONIALS = [
  {
    id: 1,
    userName: "Maica Angelica Orsenado",
    userDesignation: "page.testimonial.asean_designation_1",
    description: "page.testimonial.asean_description_1",
    src: TestimonialsMaicaAngelicaOrsendo,
  },
  {
    id: 2,
    userName: "May Therese A. Brosas",
    userDesignation: "page.testimonial.asean_designation_3",
    description: "page.testimonial.asean_description_3",
    src: TestimonialsMayTherse,
  },
  {
    id: 3,
    userName: "Eng Joo Lim",
    userDesignation: "page.testimonial.asean_designation_4",
    description: "page.testimonial.asean_description_4",
    src: TestimonialsEngJooLim,
  },
];
