import { FC, useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";

import CardHeader from "../CardHeader";
import SimpleImage from "../../../Media/SimpleImage";
import { Anchor } from "../../Anchor";

interface HeaderItems {
  title: string;
  href: string;
  href_jp?: string;
  anchorText: string;
  variant?: string;
  external?: boolean;
}
interface Item {
  src: string;
  href?: string;
  href_jp?: string;
  name: string;
  description: string;
}
interface Props {
  headerItems?: HeaderItems;
  items: Item[];
}
const NewsCard: FC<Props> = ({ headerItems, items }) => {
  const intl = useIntl();
  const [titleLength, setTitleLength] = useState(130);
  const { locale } = intl;
  useEffect(() => {
    const len = locale !== "en" ? 52 : 130;
    setTitleLength(len);
  }, [locale]);

  return (
    <Container>
      {headerItems && <CardHeader {...headerItems}></CardHeader>}
      <Row>
        {items.map((item, index) => {
          return (
            <Col lg={4} md={4} xs={12} key={index}>
              <div className="thumb-img">
                <SimpleImage src={item.src} className="grid-img" />
              </div>
              {item?.href ? (
                <Anchor href={item.href} href_jp={item.href_jp} external={true}>
                  <h5>
                    <FormattedMessage id={item.name} />
                  </h5>
                  <p>
                    {intl.formatMessage({ id: item.description })}
                    {/* {intl.formatMessage({ id: item.description }).length >
                      titleLength && "..."} */}
                  </p>
                </Anchor>
              ) : (
                <>
                  <h5>
                    <FormattedMessage id={item.name} />
                  </h5>
                  <p>{intl.formatMessage({ id: item.description })}</p>
                </>
              )}
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};
export default NewsCard;
